@import url(https://fonts.googleapis.com/css2?family=Michroma&display=swap);
@import url(https://use.typekit.net/pro2zmf.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Adding the fonts to the Project */
/* Decalring Global Vars for the color scheme */
:root {
  --primary-color: #67ba43;
  --app-background:#F5F5F5;
}

.App {
  background-image: url(/static/media/siteBackground.d66f07a3.png);
  background-blend-mode: darken;
  background-attachment: fixed;
  background-color: #ffffff;
  height: -webkit-max-content;
  height: max-content;
  display: grid;
  overflow: hidden;
}

.app-footer {
  background-color: black;
  height: 20vh;
  height: calc(1vh * 20);
  height: calc(var(--vh, 1vh) * 20);
}

.app-footer .menu-container {
  display: block;
  width: calc(355px +  1vw);
  height: 20%;
  margin: 1vh auto 30px 1vw;
}

.app-footer .menu-container .menu-options {
  display: flex;
  flex-direction: row;
}

.app-footer .menu-container .menu-options li {
  margin: 10px auto;
  list-style: none;
  font-family: Michroma;
}

.app-footer .menu-container .menu-options li a {
  text-decoration: none;
  color: #ffffff;
}

.app-footer .menu-container .menu-options li a:hover {
  color: #67ba43;
  color: var(--primary-color);
}

.app-footer .thankyou {
  font-family: Michroma;
  text-align: center;
  color: white;
  font-size: 2vh;
}
/*# sourceMappingURL=App.css.map */
.hero-container {
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  border: 5px solid black;
  color: var(--primary-color);
  background-image: linear-gradient(rgba(151, 250, 108, 0.552) 50%);
  font-family: Michroma;
  display: grid;
  margin: 0px 0px 1px 0px;
  padding: 0;
}

.hero-container .top-edge-typography {
  border-bottom: 5px solid black;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: calc(1vh + 0.5vw);
  z-index: 0;
  letter-spacing: .15em;
  height: 5vh;
  min-height: 42.2px;
}

.hero-container .top-edge-typography .top-quotebox {
  margin: auto;
  padding-right: 1vw;
  height: 100%;
}

.hero-container .top-edge-typography .top-quotebox .quote-tag {
  position: relative;
  top: 17%;
  text-align: center;
}

.hero-container .top-edge-typography .top-nametag-box {
  border-left: 5px solid black;
}

.hero-container .top-edge-typography .top-nametag-box .name-tag {
  display: block;
  width: 20vw;
  text-align: center;
  position: relative;
  top: 17%;
}

.hero-container .heroContent {
  display: flex;
  overflow: hidden;
}

.hero-container .heroContent .hero-left {
  height: 94.5vh;
  height: calc(1vh * 94.5);
  height: calc(var(--vh, 1vh) * 94.5);
  width: 60vw;
  border-right: 5px solid black;
  display: grid;
}

.hero-container .heroContent .hero-left .hero-left-top {
  height: 65%;
}

.hero-container .heroContent .hero-left .hero-left-top .hero-name {
  margin: 15vh 0 8vh 0;
  margin: calc(1vh * 15) 0 calc(1vh * 8) 0;
  margin: calc(var(--vh, 1vh) * 15) 0 calc(var(--vh, 1vh) * 8) 0;
  padding: 0;
  font-family: subway-new-york-sc, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13vw;
  text-align: center;
  letter-spacing: .05em;
  line-height: .7em;
  color: black;
}

@-webkit-keyframes glowing-pulse {
  0% {
    text-shadow: 0 0 7px #fff, 0 0 10px var(--primary-color), 0 0 21px var(--primary-color), 0 0 42px var(--primary-color), 0 0 82px var(--primary-color), 0 0 92px var(--primary-color), 0 0 102px var(--primary-color), 0 0 151px var(--primary-color);
  }
  50% {
    text-shadow: 0 0 14px #fff, 0 0 20px var(--primary-color), 0 0 42px var(--primary-color), 0 0 84px var(--primary-color), 0 0 164px var(--primary-color), 0 0 184px var(--primary-color), 0 0 204px var(--primary-color), 0 0 302px var(--primary-color);
  }
  100% {
    text-shadow: 0 0 7px #fff, 0 0 10px var(--primary-color), 0 0 21px var(--primary-color), 0 0 42px var(--primary-color), 0 0 82px var(--primary-color), 0 0 92px var(--primary-color), 0 0 102px var(--primary-color), 0 0 151px var(--primary-color);
  }
}

@keyframes glowing-pulse {
  0% {
    text-shadow: 0 0 7px #fff, 0 0 10px var(--primary-color), 0 0 21px var(--primary-color), 0 0 42px var(--primary-color), 0 0 82px var(--primary-color), 0 0 92px var(--primary-color), 0 0 102px var(--primary-color), 0 0 151px var(--primary-color);
  }
  50% {
    text-shadow: 0 0 14px #fff, 0 0 20px var(--primary-color), 0 0 42px var(--primary-color), 0 0 84px var(--primary-color), 0 0 164px var(--primary-color), 0 0 184px var(--primary-color), 0 0 204px var(--primary-color), 0 0 302px var(--primary-color);
  }
  100% {
    text-shadow: 0 0 7px #fff, 0 0 10px var(--primary-color), 0 0 21px var(--primary-color), 0 0 42px var(--primary-color), 0 0 82px var(--primary-color), 0 0 92px var(--primary-color), 0 0 102px var(--primary-color), 0 0 151px var(--primary-color);
  }
}

.hero-container .heroContent .hero-left .hero-left-top .hero-subheading {
  margin: 0;
  padding: 0;
  letter-spacing: .15em;
  font-size: 2.5vw;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  -webkit-animation-name: glowing-pulse;
          animation-name: glowing-pulse;
  -webkit-animation-duration: .2s;
          animation-duration: .2s;
  -webkit-animation-iteration-count: 15;
          animation-iteration-count: 15;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  text-shadow: 0 0 7px #fff, 0 0 10px var(--primary-color), 0 0 21px var(--primary-color), 0 0 42px var(--primary-color), 0 0 82px var(--primary-color), 0 0 92px var(--primary-color), 0 0 102px var(--primary-color), 0 0 151px var(--primary-color);
}

.hero-container .heroContent .hero-left .hero-left-bottom {
  height: 75%;
  margin-top: 0;
  display: flex;
  justify-content: space-around;
}

.hero-container .heroContent .hero-left .hero-left-bottom .node-img {
  max-height: 172px;
  margin: 0 auto;
  position: relative;
}

.hero-container .heroContent .hero-left .hero-left-bottom .react-img {
  max-width: 200px;
  max-height: 200px;
  margin: -4vh auto;
  margin: calc(1vh * -4) auto;
  margin: calc(var(--vh, 1vh) * -4) auto;
}

.hero-container .heroContent .hero-left .hero-left-bottom .vercel-img {
  max-height: 205px;
  max-width: 308px;
  margin: -4vh auto;
  margin: calc(1vh * -4) auto;
  margin: calc(var(--vh, 1vh) * -4) auto;
  padding: 0;
}

.hero-container .heroContent .hero-right {
  height: 94.5vh;
  height: calc(1vh * 94.5);
  height: calc(var(--vh, 1vh) * 94.5);
  width: 38.2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.hero-container .heroContent .hero-right .monitor-container {
  height: 65%;
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin: auto;
}

.hero-container .heroContent .hero-right .monitor-container img {
  width: 100%;
  height: 100%;
}

.hero-container .heroContent .hero-right .hero-right-button-container {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}

.hero-container .heroContent .hero-right .hero-right-button-container .cta-hero-right {
  margin: 0 auto 1vw auto;
  background-color: var(--primary-color);
  border: 1px var(--primary-color) solid;
  width: 40%;
  height: 30%;
  color: white;
  font-family: Michroma;
  font-size: 1.5vw;
  letter-spacing: .15em;
  text-decoration: none;
  padding: auto auto;
  text-transform: uppercase;
  box-shadow: 20px 20px 6px rgba(0, 0, 0, 0.5);
}

.hero-container .heroContent .hero-right .hero-right-button-container .cta-hero-right .link {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
}

.hero-container .heroContent .hero-right .hero-right-button-container .cta-hero-right:hover {
  margin: 0 auto 1vw auto;
  background-color: #F5F5F5;
  border: 1px var(--primary-color) solid;
  width: 40%;
  height: 30%;
  color: var(--primary-color);
  font-family: Michroma;
  letter-spacing: .15em;
  text-decoration: none;
  padding: auto auto;
  cursor: crosshair;
  text-transform: uppercase;
  box-shadow: 20px 20px 6px rgba(0, 0, 0, 0.5);
}

.hero-container .heroContent .hero-right .hero-right-button-container .cta-hero-right:hover .link {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--primary-color);
}

.hero-container .heroContent .hero-right .hero-right-button-container .cta-hero-right-subtext {
  font-size: 1.4vw;
  font-weight: bold;
  letter-spacing: .15em;
}

@media only screen and (max-width: 1249px) and (min-width: 413px) {
  .hero-container {
    width: 100vw;
  }
  .hero-container .top-edge-typography {
    display: none;
  }
  .hero-container .heroContent {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60% 30%;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  .hero-container .heroContent .hero-left {
    height: 60vh;
    height: calc(1vh * 60);
    height: calc(var(--vh, 1vh) * 60);
    width: 100%;
    border-right: 0px;
  }
  .hero-container .heroContent .hero-left .hero-left-top .hero-name {
    width: 80%;
    font-size: 25vmin;
    margin: calc(1vh * 15) auto 4vw auto;
    margin: calc(var(--vh, 1vh) * 15) auto 4vw auto;
  }
  .hero-container .heroContent .hero-left .hero-left-top .hero-subheading {
    font-size: 4vmin;
    color: #fff;
    text-shadow: 0 0 7px var(--primary-color), 0 0 10px var(--primary-color), 0 0 21px var(--primary-color), 0 0 42px var(--primary-color), 0 0 82px var(--primary-color), 0 0 92px var(--primary-color), 0 0 102px var(--primary-color), 0 0 151px var(--primary-color);
  }
  .hero-container .heroContent .hero-left .hero-left-bottom {
    display: none;
  }
  .hero-container .heroContent .hero-right {
    width: 100%;
    height: 100%;
    margin: none;
  }
  .hero-container .heroContent .hero-right .monitor-container {
    display: none;
  }
  .hero-container .heroContent .hero-right .hero-right-button-container {
    height: 100%;
    width: 100%;
    margin-top: 5%;
    justify-content: revert;
  }
  .hero-container .heroContent .hero-right .hero-right-button-container .cta-hero-right {
    font-size: 3.5vmin;
  }
  .hero-container .heroContent .hero-right .hero-right-button-container .cta-hero-right-subtext {
    font-size: calc(25px - 0.1vh - .1vw);
  }
}

@media only screen and (max-width: 500px) {
  .hero-container {
    width: 100vw;
  }
  .hero-container .top-edge-typography {
    display: none;
  }
  .hero-container .heroContent {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60% 40%;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  .hero-container .heroContent .hero-left {
    height: 60vh;
    height: calc(1vh * 60);
    height: calc(var(--vh, 1vh) * 60);
    width: 100%;
    border-right: 0px;
  }
  .hero-container .heroContent .hero-left .hero-left-top .hero-name {
    width: 80%;
    font-size: calc(125px - 1vw);
    margin: calc(1vh * 15) auto 4vw auto;
    margin: calc(var(--vh, 1vh) * 15) auto 4vw auto;
  }
  .hero-container .heroContent .hero-left .hero-left-top .hero-subheading {
    font-size: 4vmin;
    color: #fff;
    text-shadow: 0 0 7px var(--primary-color), 0 0 10px var(--primary-color), 0 0 21px var(--primary-color), 0 0 42px var(--primary-color), 0 0 82px var(--primary-color), 0 0 92px var(--primary-color), 0 0 102px var(--primary-color), 0 0 151px var(--primary-color);
  }
  .hero-container .heroContent .hero-left .hero-left-bottom {
    display: none;
  }
  .hero-container .heroContent .hero-right {
    width: 100%;
    height: 100%;
    margin: none;
  }
  .hero-container .heroContent .hero-right .monitor-container {
    display: none;
  }
  .hero-container .heroContent .hero-right .hero-right-button-container {
    height: 100%;
    width: 100%;
    justify-content: revert;
  }
  .hero-container .heroContent .hero-right .hero-right-button-container .cta-hero-right {
    font-size: 3.5vw;
  }
  .hero-container .heroContent .hero-right .hero-right-button-container .cta-hero-right-subtext {
    font-size: calc(18px - 0.1vh - .1vw);
  }
}
/*# sourceMappingURL=HeroSection.css.map */
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.aboutme-container {
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(103, 186, 67, 0.384);
  font-family: Michroma;
  background-image: url(/static/media/self.ca3f53a8.png);
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 30%;
  background-size: 50%;
  border-bottom: solid black 5px;
}

.aboutme-container .aboutme-header {
  text-transform: uppercase;
  margin: auto auto auto 3vw;
  padding: 0;
  color: white;
  font-size: calc(1vh * 10);
  font-size: calc(var(--vh, 1vh) * 10);
  font-weight: normal;
  letter-spacing: .1em;
  text-shadow: 1vmin 1vmin rgba(0, 0, 0, 0.137);
}

.aboutme-container .aboutme-content-conatiner {
  display: grid;
  grid-template-columns: 80vw 20vw;
  grid-template-rows: 75vh;
  grid-template-rows: calc(1vh * 75);
      grid-template-rows: calc(var(--vh, 1vh) * 75);
  padding: 0;
  margin: 0;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left {
  width: 75vw;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-column-gap: 1vw;
  grid-row-gap: 1vw;
  margin-left: 3vw;
  padding: 0;
  font-size: calc(20px - calc(1vh * .1) - .1vw);
  font-size: calc(20px - calc(var(--vh, 1vh) * .1) - .1vw);
  font-weight: bold;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills {
  height: 100%;
  width: 100%;
  background-color: orchid;
  position: relative;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .window-background-img {
  width: 100%;
  height: 100%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-skills-subheader.languages {
  font-size: 120%;
  top: 15%;
  left: 40%;
  color: var(--primary-color);
  position: absolute;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list {
  list-style: none;
  top: 30%;
  left: 5%;
  position: absolute;
  margin-bottom: -3vh;
  margin-bottom: calc(1vh * -3);
  margin-bottom: calc(var(--vh, 1vh) * -3);
  height: 70%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list li {
  height: 14%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.java {
  width: 35vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.java .progressbar-title {
  width: 35vw;
  display: flex;
  justify-content: space-between;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.java .progressbar-title h4 {
  width: 20%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.java .progressbar-title h5 {
  padding: 1%;
  margin-right: 2%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.java .progressbar-title .percent {
  margin: 1% 0 0 0;
  height: 1vw;
  width: 27vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.java .progressbar-title .percent .value {
  height: 100%;
  width: 1%;
  background-color: #27ae60;
  transition: width 2s linear;
  transition-delay: 0.5s;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.java .progressbar-title .percent .value.animated {
  width: 85%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.java .progressbar-title .percent .value:hover {
  background-color: gold;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.js {
  width: 35vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.js .progressbar-title {
  width: 35vw;
  display: flex;
  justify-content: space-between;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.js .progressbar-title h4 {
  width: 20%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.js .progressbar-title h5 {
  padding: 1%;
  margin-right: 2%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.js .progressbar-title .percent {
  margin: 1% 0 0 0;
  height: 1vw;
  width: 27vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.js .progressbar-title .percent .value {
  height: 100%;
  width: 1%;
  background-color: #27ae60;
  transition: width 2s linear;
  transition-delay: 0.5s;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.js .progressbar-title .percent .value.animated {
  width: 90%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.js .progressbar-title .percent .value:hover {
  background-color: gold;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.python {
  width: 35vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.python .progressbar-title {
  width: 35vw;
  display: flex;
  justify-content: space-between;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.python .progressbar-title h4 {
  width: 20%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.python .progressbar-title h5 {
  padding: 1%;
  margin-right: 2%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.python .progressbar-title .percent {
  margin: 1% 0 0 0;
  height: 1vw;
  width: 27vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.python .progressbar-title .percent .value {
  height: 100%;
  width: 1%;
  background-color: #27ae60;
  transition: width 2s linear;
  transition-delay: 0.5s;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.python .progressbar-title .percent .value.animated {
  width: 80%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.python .progressbar-title .percent .value:hover {
  background-color: gold;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.html {
  width: 35vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.html .progressbar-title {
  width: 35vw;
  display: flex;
  justify-content: space-between;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.html .progressbar-title h4 {
  width: 20%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.html .progressbar-title h5 {
  padding: 1%;
  margin-right: 2%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.html .progressbar-title .percent {
  margin: 1% 0 0 0;
  height: 1vw;
  width: 27vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.html .progressbar-title .percent .value {
  height: 100%;
  width: 1%;
  background-color: #27ae60;
  transition: width 2s linear;
  transition-delay: 0.5s;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.html .progressbar-title .percent .value.animated {
  width: 90%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.html .progressbar-title .percent .value:hover {
  background-color: gold;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.css {
  width: 35vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.css .progressbar-title {
  width: 35vw;
  display: flex;
  justify-content: space-between;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.css .progressbar-title h4 {
  width: 20%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.css .progressbar-title h5 {
  padding: 1%;
  margin-right: 2%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.css .progressbar-title .percent {
  margin: 1% 0 0 0;
  height: 1vw;
  width: 27vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.css .progressbar-title .percent .value {
  height: 100%;
  width: 1%;
  background-color: #27ae60;
  transition: width 2s linear;
  transition-delay: 0.5s;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.css .progressbar-title .percent .value.animated {
  width: 85%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.css .progressbar-title .percent .value:hover {
  background-color: gold;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.php {
  width: 35vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.php .progressbar-title {
  width: 35vw;
  display: flex;
  justify-content: space-between;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.php .progressbar-title h4 {
  width: 20%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.php .progressbar-title h5 {
  padding: 1%;
  margin-right: 2%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.php .progressbar-title .percent {
  margin: 1% 0 0 0;
  height: 1vw;
  width: 27vw;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.php .progressbar-title .percent .value {
  height: 100%;
  width: 1%;
  background-color: #27ae60;
  transition: width 2s linear;
  transition-delay: 0.5s;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.php .progressbar-title .percent .value.animated {
  width: 70%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills .aboutme-languages-list .aboutme-listitem.php .progressbar-title .percent .value:hover {
  background-color: gold;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-holder {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-interest {
  height: 100%;
  width: 100%;
  background-color: orchid;
  position: relative;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-interest .window-background-img {
  width: 100%;
  height: 100%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-interest .aboutme-skills-subheader.interest {
  font-size: 120%;
  color: var(--primary-color);
  top: 12%;
  width: 100%;
  text-align: center;
  position: absolute;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-interest .wrapper {
  height: 70%;
  width: 94%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 23%;
  left: 4%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-interest .wrapper .interest-list {
  padding-right: 10%;
  left: 10%;
  position: absolute;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-summary {
  height: 100%;
  width: 100%;
  position: relative;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-summary .window-background-img {
  width: 100%;
  height: 100%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-summary .aboutme-skills-subheader.summary {
  font-size: 120%;
  color: var(--primary-color);
  top: 12%;
  width: 100%;
  text-align: center;
  position: absolute;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-summary .wrapper {
  height: 70%;
  width: 94%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 23%;
  left: 4%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-summary .wrapper .aboutme-summary-p {
  left: 4%;
  position: absolute;
  width: 90%;
}

.aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-summary .wrapper .aboutme-summary-p .aboutme-summary-wordhype {
  color: var(--primary-color);
}

.aboutme-container .aboutme-content-conatiner .aboutme-right {
  position: relative;
}

.aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container {
  justify-content: center;
  width: 100%;
  height: 50%;
  bottom: 5%;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .aboutme-foldericon {
  height: 70%;
  margin: auto;
}

.aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .folder-cap {
  font-size: 1.5vmin;
  font-weight: bold;
  margin: -10% auto 0 auto;
}

.aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .aboutme-download-button {
  margin: auto 10% 0px 0px;
  background-color: white;
  border: 1px white solid;
  width: 90%;
  height: 12vh;
  height: calc(1vh * 12);
  height: calc(var(--vh, 1vh) * 12);
  color: black;
  font-family: Michroma;
  font-size: 1.5vw;
  letter-spacing: .15em;
  text-transform: uppercase;
  box-shadow: 20px 20px 6px rgba(0, 0, 0, 0.5);
}

.aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .aboutme-download-button a {
  color: black;
  text-decoration: none;
}

.aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .aboutme-download-button:hover {
  background-color: var(--primary-color);
}

.aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .aboutme-download-button:hover a {
  color: white;
}

@media only screen and (max-width: 1249px) {
  .aboutme-container {
    width: 100vw;
    background-position-x: 50%;
    background-position-y: 30%;
    background-size: calc(750px - 5vw);
    border-bottom: solid black 5px;
  }
  .aboutme-container .aboutme-header {
    font-size: 7.5vmin;
  }
  .aboutme-container .aboutme-content-conatiner {
    display: grid;
    width: 100vw;
    height: 90vh;
    height: calc(1vh * 90);
    height: calc(var(--vh, 1vh) * 90);
    grid-template-columns: 90vw;
    grid-template-rows: 90% 10%;
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0;
    -webkit-column-gap: 0;
    grid-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
    padding: 0;
    margin: 0;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-left {
    grid-template-columns: 110%;
    grid-template-rows: 40% 60%;
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
    margin: auto;
    padding: 0;
    height: 100%;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills {
    display: none;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-interest {
    display: none;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-summary {
    /* height: calc(500px - 18.5vw); */
    width: 100%;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-right {
    position: relative;
    bottom: calc(23.5px + 16vw);
    right: -45vw;
    width: 52vw;
    height: 22vh;
    height: calc(1vh * 22);
    height: calc(var(--vh, 1vh) * 22);
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container {
    display: flex;
    height: calc(277px - 1vw);
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .aboutme-foldericon {
    margin: 2vh -5vh 1vh auto;
    margin: calc(1vh * 2) -5vw calc(1vh * 1) auto;
    margin: calc(var(--vh, 1vh) * 2) -5vw calc(var(--vh, 1vh) * 1) auto;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .folder-cap {
    font-size: 2.5vmin;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .aboutme-download-button {
    font-size: calc(10.69px + .8vw);
  }
}

@media only screen and (max-width: 500px) {
  .aboutme-container {
    background-size: 120%;
  }
  .aboutme-container .aboutme-header {
    margin: 5vh 1vw 1vh 1vw;
    margin: calc(1vh * 5) 1vw calc(1vh * 1) 1vw;
    margin: calc(var(--vh, 1vh) * 5) 1vw calc(var(--vh, 1vh) * 1) 1vw;
    font-size: 11vmin;
  }
  .aboutme-container .aboutme-content-conatiner {
    display: grid;
    height: 80vh;
    height: calc(1vh * 80);
    height: calc(var(--vh, 1vh) * 80);
    grid-template-columns: 90vw;
    grid-template-rows: 90% 10%;
    grid-row-gap: 0;
    row-gap: 0;
    -webkit-column-gap: 0;
            grid-column-gap: 0;
            column-gap: 0;
    padding: 0px;
    margin: 0px;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-left {
    grid-template-columns: 110%;
    grid-template-rows: 30% 70%;
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
    margin: auto;
    padding: 0;
    height: 100%;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-skills {
    display: none;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-interest {
    display: none;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-left .aboutme-summary {
    font-size: calc(4px + calc(1vh * 1.2) + .05vw);
    font-size: calc(4px + calc(var(--vh, 1vh) * 1.2) + .05vw);
    width: 100%;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-right {
    position: relative;
    bottom: calc(85.5px + calc(1vh * 16));
    bottom: calc(85.5px + calc(var(--vh, 1vh) * 16));
    right: -45vw;
    width: 55vw;
    height: 39vh;
    height: calc(1vh * 39);
    height: calc(var(--vh, 1vh) * 39);
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container {
    height: calc(220px - 15vw);
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-right .aboutme-folder-container .aboutme-foldericon {
    margin: auto 1vw 2vh auto;
    margin: auto 1vw calc(1vh * 2) auto;
    margin: auto 1vw calc(var(--vh, 1vh) * 2) auto;
    size: 50%;
  }
  .aboutme-container .aboutme-content-conatiner .aboutme-right .folder-cap {
    font-size: 2.5vmin;
  }
}
/*# sourceMappingURL=AboutMe.css.map */
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.project-icon-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.project-icon-container .icons {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: white;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: solid black 2px;
  position: relative;
  z-index: 1;
}

.project-icon-container .project-title-overlay {
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-image: linear-gradient(rgba(255, 0, 0, 0) 1%, black 99%);
}

.project-icon-container .project-title {
  position: absolute;
  padding: 1% 3%;
  height: 20%;
  width: 100%;
  z-index: 3;
  bottom: 0px;
  color: white;
  font-size: calc(12px + .2vmax);
  margin-bottom: 15px;
}

.project-icon-container:hover .icons {
  border-color: gold;
}

@-webkit-keyframes project-title-overlay-change {
  0% {
    opacity: 70%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes project-title-overlay-change {
  0% {
    opacity: 70%;
  }
  100% {
    opacity: 100%;
  }
}

@-webkit-keyframes project-title-icon-change {
  0% {
    box-shadow: 0px 0px 0px 0px var(--primary-color);
  }
  50% {
    box-shadow: 0px 0px 20px 5px var(--primary-color);
  }
  100% {
    box-shadow: 0px 0px 40px 10px var(--primary-color);
  }
}

@keyframes project-title-icon-change {
  0% {
    box-shadow: 0px 0px 0px 0px var(--primary-color);
  }
  50% {
    box-shadow: 0px 0px 20px 5px var(--primary-color);
  }
  100% {
    box-shadow: 0px 0px 40px 10px var(--primary-color);
  }
}

.project-icon-container.selected {
  box-shadow: 0px 0px 40px 10px var(--primary-color);
  -webkit-animation-name: project-title-icon-change;
          animation-name: project-title-icon-change;
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.project-icon-container.selected .project-title {
  color: var(--primary-color);
}

@media only screen and (max-width: 500px) {
  .project-icon-container .project-title {
    height: 30%;
  }
}
/*# sourceMappingURL=Projects.css.map */
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.portfolio-container {
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  font-family: Michroma;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: 78% 12.5%;
}

.portfolio-container .portfolio-header {
  text-transform: uppercase;
  margin: auto auto auto 3vw;
  padding: 0;
  color: var(--primary-color);
  font-size: calc(1vh * 10);
  font-size: calc(var(--vh, 1vh) * 10);
  font-weight: normal;
  letter-spacing: .1em;
  text-shadow: 7px 8px rgba(0, 0, 0, 0.137);
}

.portfolio-container .portfolio-content-container {
  margin: auto;
  width: 95%;
  height: 70%;
  display: flex;
}

.portfolio-container .portfolio-content-container .portfolio-left {
  width: 40%;
  height: 100%;
}

.portfolio-container .portfolio-content-container .portfolio-left .portfolio-projects-header {
  color: var(--primary-color);
  text-align: center;
}

.portfolio-container .portfolio-content-container .portfolio-left .project-list-container {
  margin: 4vw auto 0 auto;
  width: 80%;
  height: 55%;
}

.portfolio-container .portfolio-content-container .portfolio-left .project-list-container ul {
  font-size: 1vw;
  list-style: none;
  display: grid;
  grid-template-columns: 48.5% 48.5%;
  grid-template-rows: 55% 55%;
  grid-column-gap: 1.3vw;
  grid-row-gap: .5vw;
  height: 75%;
  width: 100%;
}

.portfolio-container .portfolio-content-container .portfolio-left .contact-me-cta {
  margin: auto;
  text-align: center;
  font-size: 1.2vw;
}

.portfolio-container .portfolio-content-container .portfolio-left .contact-me-cta .portfolio-contactme-wordhype {
  color: var(--primary-color);
  text-decoration: none;
}

.portfolio-container .portfolio-content-container .portfolio-left img {
  width: 50%;
  height: 30%;
  margin-left: 25%;
}

.portfolio-container .portfolio-content-container .portfolio-right {
  width: 60%;
  height: 100%;
}

.portfolio-container .portfolio-content-container .portfolio-right .portfolio-preview-container {
  height: 65%;
}

.portfolio-container .portfolio-content-container .portfolio-right .portfolio-preview-container .preview-image {
  margin: 0 10% 0 0%;
  height: 100%;
  width: 90%;
}

.portfolio-container .portfolio-content-container .portfolio-right .site-des-container {
  margin-top: 5%;
  padding: 0vh 10px 1vh 10px;
  padding: 0vh 10px calc(1vh * 1) 10px;
  padding: 0vh 10px calc(var(--vh, 1vh) * 1) 10px;
  height: 30%;
  border: solid black 3px;
  background-color: black;
  box-shadow: 10px 30px 25px 30px black;
  overflow-y: scroll;
}

.portfolio-container .portfolio-content-container .portfolio-right .site-des-container .portfolio-site-description-header {
  font-size: calc(10.69px + .8vw);
  color: var(--primary-color);
}

.portfolio-container .portfolio-content-container .portfolio-right .site-des-container .portfolio-site-details {
  font-size: calc(14px + .2vmax);
  letter-spacing: .05em;
  line-height: 1.5em;
  color: white;
}

@media only screen and (max-width: 1249px) {
  .portfolio-container {
    background-image: none !important;
    width: 100vw;
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    overflow: visible;
  }
  .portfolio-container .portfolio-header {
    margin: 2vw auto 0vw 3vw;
    font-size: 8vmin;
  }
  .portfolio-container .portfolio-content-container {
    margin: auto;
    flex-direction: column-reverse;
    height: 85%;
  }
  .portfolio-container .portfolio-content-container .portfolio-left {
    height: 25%;
    width: 100%;
    margin-bottom: 5%;
    position: relative;
  }
  .portfolio-container .portfolio-content-container .portfolio-left .portfolio-projects-header {
    display: none;
  }
  .portfolio-container .portfolio-content-container .portfolio-left .project-list-container {
    width: 100%;
    height: 100%;
    margin: 1% 3%;
    padding-top: 1vh;
    padding-top: calc(1vh * 1);
    padding-top: calc(var(--vh, 1vh) * 1);
  }
  .portfolio-container .portfolio-content-container .portfolio-left .project-list-container ul {
    font-size: 1vw;
    list-style: none;
    display: grid;
    grid-template-columns: 45% 45% 45% 45%;
    grid-template-rows: 100%;
    grid-column-gap: 1.3vw;
    height: 100%;
    width: 94%;
    overflow-x: scroll;
  }
  .portfolio-container .portfolio-content-container .portfolio-left .contact-me-cta {
    display: none;
  }
  .portfolio-container .portfolio-content-container .portfolio-left img {
    display: block;
    height: auto;
    width: calc(20% + 1vmin);
    float: right;
    display: none;
  }
  .portfolio-container .portfolio-content-container .portfolio-right {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    margin: 0px 0px;
    height: 70%;
    width: 100%;
  }
  .portfolio-container .portfolio-content-container .portfolio-right .portfolio-preview-container {
    margin: 0px 0px;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .portfolio-container .portfolio-content-container .portfolio-right .portfolio-preview-container .preview-image {
    height: 100%;
    width: auto;
    margin: 0px;
  }
  .portfolio-container .portfolio-content-container .portfolio-right .site-des-container {
    margin: 0%;
    height: 100%;
  }
  .portfolio-container .portfolio-content-container .portfolio-right .site-des-container .portfolio-site-description-header {
    font-size: calc(28px - 0.1vh - .1vw);
  }
  .portfolio-container .portfolio-content-container .portfolio-right .site-des-container .portfolio-site-details {
    font-size: calc(24px - 0.1vh - .1vw);
  }
  .portfolio-container .portfolio-content-container .portfolio-left {
    height: 18%;
  }
}

@media only screen and (max-width: 500px) {
  .portfolio-container .portfolio-header {
    margin: 3vw 0vw 0vh 0vw;
    font-size: 11vmin;
  }
  .portfolio-container .portfolio-content-container .portfolio-right {
    height: 80%;
  }
  .portfolio-container .portfolio-content-container .portfolio-right .portfolio-preview-container {
    height: 50%;
  }
  .portfolio-container .portfolio-content-container .portfolio-right .portfolio-preview-container .preview-image {
    height: 100%;
  }
  .portfolio-container .portfolio-content-container .portfolio-right .site-des-container {
    margin: 0%;
    height: 100%;
  }
  .portfolio-container .portfolio-content-container .portfolio-right .site-des-container .portfolio-site-description-header {
    font-size: calc(17px - 0.1vh - .1vw);
  }
  .portfolio-container .portfolio-content-container .portfolio-right .site-des-container .portfolio-site-details {
    font-size: calc(14px - 0.1vh - .1vw);
  }
}
/*# sourceMappingURL=Portfolio.css.map */
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact-container {
  min-height: -webkit-fill-available;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  font-family: Michroma;
}

.contact-container .contact-header {
  text-transform: uppercase;
  margin: auto auto auto 3vw;
  padding: 0;
  color: var(--primary-color);
  font-size: calc(1vh * 10);
  font-size: calc(var(--vh, 1vh) * 10);
  font-weight: normal;
  letter-spacing: .1em;
  text-shadow: 7px 8px rgba(0, 0, 0, 0.137);
}

.contact-container .contact-content-container {
  display: grid;
  grid-template-columns: 45vw 55vw;
  grid-template-rows: 80%;
  overflow: hidden;
  height: 90%;
  margin: auto;
}

.contact-container .contact-content-container .contact-left {
  height: 100%;
  width: 100%;
}

.contact-container .contact-content-container .contact-left .contact-form-container .contact-form-top {
  font-size: calc(20px - 0.1vh - .1vw);
  background-color: rgba(128, 128, 128, 0.498);
  width: 60%;
  height: 60%;
  margin: calc(1vh * 5) auto 0 auto;
  margin: calc(var(--vh, 1vh) * 5) auto 0 auto;
  border: black 3px solid;
  padding: 1%;
}

.contact-container .contact-content-container .contact-left .contact-form-container .contact-form-top .name-input {
  width: 100%;
  height: 10%;
  margin: 5% auto;
  font-size: calc(27px + .2vmax);
}

.contact-container .contact-content-container .contact-left .contact-form-container .contact-form-top .email-input {
  height: 10%;
  width: 100%;
  font-size: calc(27px + .2vmax);
  margin: 5% auto;
}

.contact-container .contact-content-container .contact-left .contact-form-container .contact-form-top .tel-input {
  height: 10%;
  width: 100%;
  font-size: calc(27px + .2vmax);
  margin: 5% auto;
}

.contact-container .contact-content-container .contact-left .contact-form-container .contact-form-top .contact-radio-buttons {
  margin: 6% auto;
  border: none;
}

.contact-container .contact-content-container .contact-left .contact-form-container .contact-form-top .contact-radio-buttons input {
  margin: 3% auto;
  font-size: calc(14px + .2vmax);
  accent-color: var(--primary-color);
}

.contact-container .contact-content-container .contact-left .contact-form-container .contact-form-top .contact-radio-buttons .contact-option-label {
  margin: auto 2%;
}

.contact-container .contact-content-container .contact-left .contact-form-container .contact-form-bottom input {
  margin: 2% 35% 0 35%;
  width: 30%;
  height: 6vh;
  height: calc(1vh * 6);
  height: calc(var(--vh, 1vh) * 6);
  font-family: Michroma;
  font-weight: 500;
  font-size: calc(14px + .2vmax);
  letter-spacing: .15em;
  color: white;
  background-color: var(--primary-color);
  border: white 1px solid;
  text-transform: uppercase;
}

.contact-container .contact-content-container .contact-left .contact-form-container .contact-form-bottom input:hover {
  color: var(--primary-color);
  background-color: white;
  border: var(--primary-color) 1px solid;
}

.contact-container .contact-content-container .contact-right {
  height: 100%;
  width: 100%;
}

.contact-container .contact-content-container .contact-right img {
  height: 130%;
  position: relative;
}

@media only screen and (max-width: 1249px) {
  .contact-container {
    background-image: url(/static/media/world.f87c453f.png);
    background-repeat: no-repeat;
    background-position: 0 220px;
  }
  .contact-container .contact-header {
    margin: 0 auto 8vh 3vw;
    margin: 0 auto calc(1vh * 8) 3vw;
    margin: 0 auto calc(var(--vh, 1vh) * 8) 3vw;
    font-size: 9vmin;
  }
  .contact-container .contact-content-container {
    display: grid;
    grid-template-columns: 90vw;
    grid-template-rows: 80%;
    overflow: hidden;
    margin: 0px 0px 0px 5%;
  }
  .contact-container .contact-content-container .contact-left .contact-form-container {
    margin: 0px 0px 0px 0px;
    padding: 0px;
    height: 100%;
  }
  .contact-container .contact-content-container .contact-left .contact-form-container form {
    height: 100%;
  }
  .contact-container .contact-content-container .contact-left .contact-form-container form .contact-form-top {
    background-color: rgba(128, 128, 128, 0.798);
    margin: 0px auto;
    width: calc(403px + 6vmax);
    height: 100%;
  }
  .contact-container .contact-content-container .contact-left .contact-form-container form .contact-form-bottom {
    width: 100%;
    height: 15%;
  }
  .contact-container .contact-content-container .contact-left .contact-form-container form .contact-form-bottom input {
    display: block;
    margin: 1vh auto;
    margin: calc(1vh * 1) auto;
    margin: calc(var(--vh, 1vh) * 1) auto;
    width: calc(403px + 6vmax);
  }
  .contact-container .contact-content-container .contact-right {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .contact-container .contact-header {
    margin: 0 auto 8vh 3vw;
    margin: 0 auto calc(1vh * 8) 3vw;
    margin: 0 auto calc(var(--vh, 1vh) * 8) 3vw;
    font-size: 12vmin;
  }
  .contact-container .contact-content-container .contact-left .contact-form-container form .contact-form-top {
    width: 100%;
    font-size: calc(16px - 0.1vh - .1vw);
  }
  .contact-container .contact-content-container .contact-left .contact-form-container form .contact-form-bottom input {
    width: 100%;
  }
}
/*# sourceMappingURL=Contact.css.map */
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar-container.isClosed {
  right: 0;
  margin: auto .5%;
  top: 15%;
  position: fixed;
  display: flex;
  z-index: 1;
  width: calc(85px + 1vw);
  height: 10vh;
  height: calc(1vh * 10);
  height: calc(var(--vh, 1vh) * 10);
}

.navbar-container.isClosed .nav-icon-closed {
  height: 100%;
  width: calc(85px + 1vw);
  margin-right: 0px;
}

.navbar-container.isClosed .nav-icon-closed .open {
  margin-left: 20%;
  height: 100%;
  width: auto;
}

.navbar-container.isClosed .nav-icon-closed .open:hover {
  height: 110%;
}

.navbar-container.isClosed .not-visible {
  display: none;
}

.navbar-container.isOpen {
  right: 0;
  margin: auto 0;
  top: 0;
  position: fixed;
  display: flex;
  z-index: 1;
  width: 45vw;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: black;
}

.navbar-container.isOpen .nav-icon-open {
  height: 10%;
  width: calc(85px + 1vw);
  margin-left: 35vw;
}

.navbar-container.isOpen .nav-icon-open .close {
  margin-right: 20%;
  height: 100%;
  width: 10vw;
  color: var(--primary-color);
}

.navbar-container.isOpen .nav-icon-open .close:hover {
  height: 110%;
  color: white;
}

.navbar-container.isOpen .not-visible {
  display: none;
}

.navbar-container.isOpen .drawer {
  position: absolute;
  margin: 25vh 10% auto 10%;
  margin: calc(1vh * 25) 10% auto 10%;
  margin: calc(var(--vh, 1vh) * 25) 10% auto 10%;
  height: 70%;
  height: calc(1vh * 70);
  height: calc(var(--vh, 1vh) * 70);
  text-align: center;
  width: 80%;
  display: block;
}

.navbar-container.isOpen .drawer .menu-options {
  list-style: none;
  height: 80%;
  padding: 10% auto 5% auto;
}

.navbar-container.isOpen .drawer .menu-options .menu-item {
  margin: 5% auto;
}

.navbar-container.isOpen .drawer .menu-options .menu-item .link {
  font-size: calc(18px + .4vmax);
  font-family: Michroma;
  text-decoration: none;
  color: white;
}

.navbar-container.isOpen .drawer .menu-options .menu-item:hover .link {
  color: var(--primary-color);
}

.navbar-container.isOpen .drawer .social-links {
  height: 20%;
}

.navbar-container.isOpen .drawer .social-links .text {
  font-size: calc(14px + .2vmax);
  font-family: Michroma;
  text-decoration: none;
  color: white;
}

.navbar-container.isOpen .drawer .social-links a .linkedin {
  height: 40%;
  width: auto;
  color: white;
}

.navbar-container.isOpen .drawer .social-links a .github {
  height: 40%;
  width: auto;
  color: white;
}

.navbar-container.isOpen .drawer .social-links a .fiverr {
  margin-left: .5rem;
  height: 40%;
  width: auto;
  color: white;
}

.navbar-container.isOpen .drawer .social-links a .linkedin:hover {
  height: 42%;
  width: auto;
  color: var(--primary-color);
}

.navbar-container.isOpen .drawer .social-links a .github:hover {
  height: 42%;
  width: auto;
  color: var(--primary-color);
}

.navbar-container.isOpen .drawer .social-links a .fiverr:hover {
  height: 42%;
  width: auto;
  color: var(--primary-color);
}

.navbar-container.not-visible {
  display: none;
}
/*# sourceMappingURL=Navbar.css.map */
